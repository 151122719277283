<template>
  <div class="row">
    <div class="col-12">
      <div
        class="heading3 q-mb-lg"
        v-text="content.titel"
        v-if="content.titel"
      />

      <div
        class="row q-col-gutter-md"
        v-if="data && !isMobile"
      >
        <ContentblokkenAccommodatieComponent
          class="col-md-4 col-xs-6 q-mb-lg"
          v-for="villa in accommodations"
          :villa="villa"
        />
      </div>

      <ClientOnly v-else-if="isMobile">
        <Swiper
          ref="swiperRef"
          class="swiperFilterContainer contentSlider"
          :modules="[SwiperNavigation, SwiperA11y]"
          :slides-per-view="1"
          navigation
        >
          <SwiperSlide
            v-for="(villa, idx) in accommodations"
            :key="idx"
          >
            <ContentblokkenAccommodatieComponent :villa="villa" />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>

      <div v-else>Geen accommodaties gevonden</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ACCOMODATION_TYPES, HOLIDAY_TYPES } from '~/config';

const props = defineProps({
  content: {
    type: Object as PropType<AccommodatiesContentblock>,
    required: true,
  },
});

const isMobile = getIsMobile();
const body = {
  accommodations: props.content.selecteerSpecifiekeAccomodaties?.split(', '),
  region: props.content.regio?.split(', '),
  [ACCOMODATION_TYPES]: props.content.accommodatieTypes?.split(', '),
  [HOLIDAY_TYPES]: props.content.vakantieTypes?.split(', '),
  accommodationFeatures: props.content.features?.split(', '),
  qualityLabels: props.content.kwaliteitslabels?.split(', '),
  maxItems: parseInt(props.content.aantalAccommodatiesTonen),
};

//TODO look into using a key in nuxt fetch https://nuxt.com/docs/api/composables/use-fetch

const config = useRuntimeConfig();
const apiUrl = `${config.public.apiBase}/accommodation/selection`;
const { data } = await useFetch(apiUrl, { method: 'post', body, key: Math.random().toString() });
const accommodations = computed(() => data.value?.accommodations);
</script>

<style lang="scss" scoped>
@media (max-width: $screen-xs) {
  .swiper {
    .swiper-button-next,
    .swiper-button-prev {
      top: 40%;
    }
  }
}
</style>
