<template>
  <div v-if="villas.length">
    <h2
      :class="titleClass"
      class="q-pb-md"
    >
      Recent bekeken
    </h2>

    <ClientOnly>
      <Swiper
        class="swiperFilterContainer contentSlider"
        :modules="[SwiperNavigation, SwiperA11y]"
        :slides-per-view="slidesPerView"
        :space-between="20"
        navigation
      >
        <SwiperSlide
          class="slide"
          v-for="(villa, idx) in villas"
          :key="idx"
        >
          <ContentblokkenAccommodatieComponent :villa="villa" />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
  </div>
</template>

<script setup>
import useAccoStore from '~/store/currentAccommodation';

const props = defineProps({
  currentSlug: {
    type: String,
    default: '',
  },

  title: {
    type: String,
    default: 'Recent bekeken',
  },

  titleClass: {
    type: String,
    default: 'heading2',
  },
});

const isMobile = getIsMobile();
const slidesPerView = computed(() => (isMobile.value ? 1 : 3));
const accoStore = useAccoStore();
const villas = computed(() => accoStore.recentViewed.filter((acco) => acco.slug !== props.currentSlug));
</script>
